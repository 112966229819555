/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code",
    h3: "h3",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "upgrade-guide",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#upgrade-guide",
    "aria-label": "upgrade guide permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Upgrade guide"), "\n", React.createElement(_components.p, null, "Upgrading from xstyled from v1.x to v3.0."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h1, {
    id: "from-v2x-to-v3x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#from-v2x-to-v3x",
    "aria-label": "from v2x to v3x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "From v2.x to v3.x"), "\n", React.createElement(_components.p, null, "xstyled v3 is focused on features."), "\n", React.createElement(_components.h2, {
    id: "object-states",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#object-states",
    "aria-label": "object states permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Object states"), "\n", React.createElement(_components.p, null, "States are now similar to breakpoints, they are specified using object syntax:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "<x.button color={{ _: 'red-500', hover: 'red-300' }} />\n")), "\n", React.createElement(_components.p, null, "They can be nested:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// Mixed screens and states\n<x.button color={{ _: 'red-600', md: { _: 'red-500', hover: 'red-300' } }} />\n// Nested states\n<x.div bg={{ first: { odd: 'blue' } } } />\n")), "\n", React.createElement(_components.p, null, "And they are configurable in theme:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "export const theme = {\n  states: {\n    hover: '&:hover',\n    // ...\n  },\n}\n")), "\n", React.createElement(_components.p, null, "Learn more in ", React.createElement(_components.a, {
    href: "/docs/hover-focus-other-states/"
  }, "states documentation"), "."), "\n", React.createElement(_components.h2, {
    id: "plugins-api",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#plugins-api",
    "aria-label": "plugins api permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Plugins API"), "\n", React.createElement(_components.p, null, "xstyled can now be fully extended with plugins using a ", React.createElement(_components.code, null, "xstyled.config.js"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { createCss, system, compose } from '@xstyled/...'\nimport { borderInline } from './utilities/border-inline'\n\nexport const { css, styled, x, createGlobalStyle } = createCss(\n  compose(system, borderInline),\n)\n")), "\n", React.createElement(_components.p, null, "Learn more in ", React.createElement(_components.a, {
    href: "/docs/adding-new-utilities/"
  }, "documentation"), "."), "\n", React.createElement(_components.h2, {
    id: "new-text-utility",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#new-text-utility",
    "aria-label": "new text utility permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "New text utility"), "\n", React.createElement(_components.p, null, "A new text utility has been added. It is similar to ", React.createElement(_components.code, null, "fontSize"), " excepts that it defines both ", React.createElement(_components.code, null, "font-size"), " and ", React.createElement(_components.code, null, "line-height"), ". It is customizabled to handle all your typography styles."), "\n", React.createElement(_components.p, null, "Learn more in ", React.createElement(_components.a, {
    href: "/docs/text/"
  }, "text utility documentation"), "."), "\n", React.createElement(_components.h2, {
    id: "new-utilities",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#new-utilities",
    "aria-label": "new utilities permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "New utilities"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "animationDuration"), ", ", React.createElement(_components.code, null, "animationTimingFunction"), " and ", React.createElement(_components.code, null, "maskSize"), " have been added to the core."), "\n", React.createElement(_components.h2, {
    id: "remove-specificity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#remove-specificity",
    "aria-label": "remove specificity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Remove specificity"), "\n", React.createElement(_components.p, null, "xstyled was using a trick to ensure props were more specific than component style. The props were injected in a ", React.createElement(_components.code, null, "&&"), ", so the class was duplicated and the specificity increased. In v3, props are now injected in the correct order, it means this specificity is not longer required."), "\n", React.createElement(_components.h2, {
    id: "new-multiple-option-in-style",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#new-multiple-option-in-style",
    "aria-label": "new multiple option in style permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "New ", React.createElement(_components.code, null, "multiple"), " option in style"), "\n", React.createElement(_components.p, null, "You can now use ", React.createElement(_components.code, null, "multiple"), " to declare style that accepts multiple styles separated by a comma. For example, ", React.createElement(_components.code, null, "box-shadow"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "<x.div boxShadow=\"light-shadow, big-shadow\" />\n")), "\n", React.createElement(_components.h2, {
    id: "breaking-changes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#breaking-changes",
    "aria-label": "breaking changes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Breaking changes"), "\n", React.createElement(_components.h3, {
    id: "no-prefixed-states",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#no-prefixed-states",
    "aria-label": "no prefixed states permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "No prefixed states"), "\n", React.createElement(_components.p, null, "States were prefixed props in v2. You can use this regular expression to find state props in your code:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "((motionSafe|motionReduce|first|last|odd|even|visited|focusWithin|hover|focus|focusVisible|focusWithin|active|disabled|placeholder|checked)[A-Z])[A-Za-z]+=\n")), "\n", React.createElement(_components.h3, {
    id: "box-has-been-removed",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#box-has-been-removed",
    "aria-label": "box has been removed permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.code, null, "Box"), " has been removed"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "Box"), " component is no longer available, you can safely replace it by ", React.createElement(_components.code, null, "x.div"), "."), "\n", React.createElement(_components.h3, {
    id: "cssproperty-option-of-style-becomes-css",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cssproperty-option-of-style-becomes-css",
    "aria-label": "cssproperty option of style becomes css permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.code, null, "cssProperty"), " option of ", React.createElement(_components.code, null, "style"), " becomes ", React.createElement(_components.code, null, "css")), "\n", React.createElement(_components.p, null, "Since the ", React.createElement(_components.a, {
    href: "/docs/adding-new-utilities/#generate-arbitrary-style"
  }, React.createElement(_components.code, null, "css"), " accepts a mixin"), ", ", React.createElement(_components.code, null, "cssProperty"), " was no longer a good name."), "\n", React.createElement(_components.h3, {
    id: "getbreakpoints-becomes-getscreens-and-usebreakpoints-becomes-usescreens",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getbreakpoints-becomes-getscreens-and-usebreakpoints-becomes-usescreens",
    "aria-label": "getbreakpoints becomes getscreens and usebreakpoints becomes usescreens permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.code, null, "getBreakpoints"), " becomes ", React.createElement(_components.code, null, "getScreens"), " and ", React.createElement(_components.code, null, "useBreakpoints"), " becomes ", React.createElement(_components.code, null, "useScreens")), "\n", React.createElement(_components.p, null, "In v1, theme section handling screens was named ", React.createElement(_components.code, null, "breakpoints"), ", since v2 theme section is now ", React.createElement(_components.code, null, "screens"), ". For consistency ", React.createElement(_components.code, null, "getBreakpoints"), " becomes ", React.createElement(_components.code, null, "getScreens"), " and ", React.createElement(_components.code, null, "useBreakpoints"), " becomes ", React.createElement(_components.code, null, "useScreens"), "."), "\n", React.createElement(_components.h3, {
    id: "thtimingfunctions-becomes-thtimingfunction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#thtimingfunctions-becomes-thtimingfunction",
    "aria-label": "thtimingfunctions becomes thtimingfunction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.code, null, "th.timingFunctions"), " becomes ", React.createElement(_components.code, null, "th.timingFunction")), "\n", React.createElement(_components.p, null, "Every ", React.createElement(_components.code, null, "th.*"), " utilities are singular, ", React.createElement(_components.code, null, "th.timingFunctions"), " was a mistake."), "\n", React.createElement(_components.h3, {
    id: "xextend-and-createx-are-gone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#xextend-and-createx-are-gone",
    "aria-label": "xextend and createx are gone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.code, null, "x.extend"), " and ", React.createElement(_components.code, null, "createX"), " are gone"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "createCss"), " instead."), "\n", React.createElement(_components.h3, {
    id: "typescript-theme-bindings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#typescript-theme-bindings",
    "aria-label": "typescript theme bindings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "TypeScript theme bindings"), "\n", React.createElement(_components.p, null, "Be sure to correctly extend xstyled theme and to follow ", React.createElement(_components.a, {
    href: "/docs/typescript/"
  }, "TypeScript guide"), "."), "\n", React.createElement(_components.h1, {
    id: "from-v1x-to-v2x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#from-v1x-to-v2x",
    "aria-label": "from v1x to v2x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "From v1.x to v2.x"), "\n", React.createElement(_components.p, null, "xstyled v2 is the new major version since v1 of xstyled released in May 2019."), "\n", React.createElement(_components.p, null, "We know that xstyled is in the core of your project and that the migration could be difficult. For that purpose, we minimized the number of changes between the two versions, there is only few breaking changes."), "\n", React.createElement(_components.h2, {
    id: "new-philosophy",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#new-philosophy",
    "aria-label": "new philosophy permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "New philosophy"), "\n", React.createElement(_components.p, null, "xstyled exposes ", React.createElement(_components.code, null, "x"), " namespace to easily create declarative components and style your website without writing CSS. The new version push this approach at the edge. The v2 still supports magic styled components, they are even better than before!"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { x } from '@xstyled/...'\n\nfunction Button() {\n  return (\n    <x.button\n      type=\"button\"\n      color=\"white\"\n      transition\n      bg={{ _: 'emerald-500', hover: 'emerald-800' }}\n    >\n      Upgrade\n    </x.button>\n  )\n}\n")), "\n", React.createElement(_components.p, null, "You don't have to choose between ", React.createElement(_components.code, null, "x"), " or ", React.createElement(_components.code, null, "styled"), ", the two approaches are supported. Feel free to use one or another or to mix both (like me)."), "\n", React.createElement(_components.h2, {
    id: "breaking-changes-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#breaking-changes-1",
    "aria-label": "breaking changes 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Breaking changes"), "\n", React.createElement(_components.h3, {
    id: "emotion-v11",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#emotion-v11",
    "aria-label": "emotion v11 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Emotion v11"), "\n", React.createElement(_components.p, null, "xstyled v2 works with Emotion v11, if you use xstyled v1 with Emotion, please read ", React.createElement(_components.a, {
    href: "https://emotion.sh/docs/migrating-to-emotion-10#incremental-migration"
  }, "the migrate guide from Emotion v10 to Emotion v11"), " first."), "\n", React.createElement(_components.h3, {
    id: "no-more-default-space",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#no-more-default-space",
    "aria-label": "no more default space permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "No more default ", React.createElement(_components.code, null, "space")), "\n", React.createElement(_components.p, null, "xstyled v1 had default ", React.createElement(_components.code, null, "space"), " built-in, in v2 ", React.createElement(_components.code, null, "space"), " are now located in default theme and are slightly different from v1. Two options:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If you have customized ", React.createElement(_components.code, null, "space"), " in your theme:")), "\n"), "\n", React.createElement(_components.p, null, "Good news! You don't have to do anything, just keep your them in v2."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If you have not customized ", React.createElement(_components.code, null, "space"), " in your theme:")), "\n"), "\n", React.createElement(_components.p, null, "Use old ", React.createElement(_components.code, null, "space"), " in your theme:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const theme = {\n  space: [0, 4, 8, 16, 24, 48, 96, 144, 192, 240],\n}\n")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If you were not using any theme:")), "\n"), "\n", React.createElement(_components.p, null, "Read ", React.createElement(_components.a, {
    href: "/docs/theme/"
  }, "customize theme documentation"), " to learn how to create a theme."), "\n", React.createElement(_components.h3, {
    id: "gridgap-becomes-gap",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#gridgap-becomes-gap",
    "aria-label": "gridgap becomes gap permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.code, null, "gridGap"), " becomes ", React.createElement(_components.code, null, "gap")), "\n", React.createElement(_components.p, null, "To follow the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/gap"
  }, "new specification"), ", ", React.createElement(_components.code, null, "gridGap"), " becomes ", React.createElement(_components.code, null, "gap"), "."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If you use ", React.createElement(_components.code, null, "gridGap"), " in your project:")), "\n"), "\n", React.createElement(_components.p, null, "Replace it by ", React.createElement(_components.code, null, "gap"), "."), "\n", React.createElement(_components.h3, {
    id: "width-becomes-w-height-becomes-h",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#width-becomes-w-height-becomes-h",
    "aria-label": "width becomes w height becomes h permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.code, null, "width"), " becomes ", React.createElement(_components.code, null, "w"), ", ", React.createElement(_components.code, null, "height"), " becomes ", React.createElement(_components.code, null, "h")), "\n", React.createElement(_components.p, null, "To reduce conflict between image attributes ", React.createElement(_components.code, null, "width"), " and ", React.createElement(_components.code, null, "height"), ", only ", React.createElement(_components.code, null, "w"), " and ", React.createElement(_components.code, null, "h"), " are now usable to define CSS properties ", React.createElement(_components.code, null, "width"), " and ", React.createElement(_components.code, null, "height"), "."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If you use ", React.createElement(_components.code, null, "width"), " or ", React.createElement(_components.code, null, "height"), " in your project:")), "\n"), "\n", React.createElement(_components.p, null, "Replace it by ", React.createElement(_components.code, null, "w"), " and ", React.createElement(_components.code, null, "h"), "."), "\n", React.createElement(_components.h3, {
    id: "no-more-size-utility",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#no-more-size-utility",
    "aria-label": "no more size utility permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "No more ", React.createElement(_components.code, null, "size"), " utility"), "\n", React.createElement(_components.p, null, "To reduce conflict between ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-size"
  }, "input attribute ", React.createElement(_components.code, null, "size")), ", size ."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If you use ", React.createElement(_components.code, null, "size"), " in your project:")), "\n"), "\n", React.createElement(_components.p, null, "Replace it by ", React.createElement(_components.code, null, "w"), " + ", React.createElement(_components.code, null, "h"), "."), "\n", React.createElement(_components.h3, {
    id: "no-more-forwardedas",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#no-more-forwardedas",
    "aria-label": "no more forwardedas permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "No more ", React.createElement(_components.code, null, "forwardedAs")), "\n", React.createElement(_components.p, null, "Everything is now simplified, you don't have to worry about ", React.createElement(_components.code, null, "forwardedAs"), " any more, ", React.createElement(_components.code, null, "as"), " works everywhere."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "If you use ", React.createElement(_components.code, null, "forwardedAs"), ":")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Replace it by ", React.createElement(_components.code, null, "as"), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, {
    id: "utilities-groups-reorganization",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#utilities-groups-reorganization",
    "aria-label": "utilities groups reorganization permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Utilities groups reorganization"), "\n", React.createElement(_components.p, null, "xstyled v2 exposes lot of new utilities, to be more consistent, utilities groups have been completely changed."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If you use utility groups like ", React.createElement(_components.code, null, "backgrounds"), ", ", React.createElement(_components.code, null, "basics"), ", ", React.createElement(_components.code, null, "borders"), ", ", React.createElement(_components.code, null, "flexboxes"), ", ", React.createElement(_components.code, null, "grids"), ", ", React.createElement(_components.code, null, "layout"), ", ", React.createElement(_components.code, null, "positioning"), ", ", React.createElement(_components.code, null, "shadows"), ", ", React.createElement(_components.code, null, "space"), ", ", React.createElement(_components.code, null, "svg"), ", ", React.createElement(_components.code, null, "typography"), " or ", React.createElement(_components.code, null, "xgrids"), ":")), "\n"), "\n", React.createElement(_components.p, null, "You have to pick new groups of utilities or single utilities. Read ", React.createElement(_components.a, {
    href: "/docs/adding-new-utilities/"
  }, "how to create utilities"), " to learn more about it."), "\n", React.createElement(_components.h3, {
    id: "breakpoints",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#breakpoints",
    "aria-label": "breakpoints permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Breakpoints"), "\n", React.createElement(_components.p, null, "xstyled v2 now uses the same breakpoints as Tailwind, it was using Bootstrap's one but landscape have changed and those from Tailwind are actually more realistic. Also, breakpoints are now read from ", React.createElement(_components.code, null, "screens"), " theme key."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If you use breakpoints and does not define them:")), "\n"), "\n", React.createElement(_components.p, null, "Add xstyled v1 breakpoints:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const theme = {\n  screens: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },\n}\n")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If you already use your own breakpoints:")), "\n"), "\n", React.createElement(_components.p, null, "Migrate them to ", React.createElement(_components.code, null, "screens"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diffjs"
  }, "  const theme = {\n-   breakpoints: { xs: 0, md: 800 },\n+   screens: { xs: 0, md: 800 },\n  }\n")), "\n", React.createElement(_components.h3, {
    id: "no-more-variant-utility",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#no-more-variant-utility",
    "aria-label": "no more variant utility permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "No more ", React.createElement(_components.code, null, "variant"), " utility"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "variant"), " utility was confusing and not really helpful, xstyled v2 encourages to use the ", React.createElement(_components.code, null, "x.*"), " syntax, so it is no longer required."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If you want use ", React.createElement(_components.code, null, "variant"), " utility and you want to get it back:")), "\n"), "\n", React.createElement(_components.p, null, "Add ", React.createElement(_components.code, null, "variant"), " utility in your codebase:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { getThemeValue, merge, warn, is, assign } from '@xstyled/util'\n\nconst variant =\n  ({ key = null, default: defaultValue, variants = {}, prop = 'variant' }) =>\n  (props) => {\n    const themeVariants = is(key) ? getThemeValue(props, key) : null\n    const computedVariants = merge(assign({}, variants), themeVariants)\n    const value = props[prop] !== undefined ? props[prop] : defaultValue\n    const result = getThemeValue(props, value, computedVariants)\n    warn(is(result), `variant \"${value}\" not found`)\n    return result\n  }\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
